import React from "react";
import Layout from "components/layout";
import AudioTranslation from "components/AudioTranslation";
import SEO from "components/seo";

function Index() {
  const title =
    "Professional Audio Translation and Transcription Service | Tomedes";
  const description =
    "Audio translation, Captioning, Subtitling and Transcription services. Join over 15,000 businesses who enjoy 24/7 support, 1 year guarantee and competitive prices. Get your quote now!";
  const keywords = "Translation, Translation services, Audios translation";
  const slug = "/audio-translation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <AudioTranslation />
    </Layout>
  );
}

export default Index;
