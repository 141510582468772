import React from "react";
import FirstAudioTranslation from "./FirstAudioTranslation";
import TranslateAgency from "components/homepage/translateAgency";
import ReviewCardsDesign2 from "components/homepage/reviewcard/design2";
import SecondAudioTranslation from "./SecondAudioTranslation";

function AudioTranslation() {
  return (
    <>
      <FirstAudioTranslation />
      <TranslateAgency content="If you’re looking for an international translation company in Boston, Tomedes has you covered. About 194,000 of Boston’s residents are from different countries, so it’s imperative to find translation services for Boston that can relate to this demographic and beyond. With the client at the heart of our philosophy, our clients have rated us 4.8 out of 5 stars, with a 97% client satisfaction rate. You can trust Tomedes to broaden your horizons." />
      <div className="bg-darkBlue">
        <ReviewCardsDesign2 />
        <SecondAudioTranslation />
      </div>
    </>
  );
}

export default AudioTranslation;
