import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { useLocation } from "@reach/router";

const addAudioTranslation = (postData) => {
  return http().post(endpoints.forms.audioTranslation, postData);
};

export function useAudioTranslation() {
  return useMutation(addAudioTranslation, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
